import { FormInput as FormInputBase } from 'components/common/FormInput'
import { styled, apply } from 'lib/styled'

const Details = styled.div(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))
const Row = styled.div(
  apply('flex-1 flex flex-row my-2', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)
const FormInput = styled(FormInputBase)({
  '&:first-of-type': apply('ml-0'),
  '&:last-of-type': apply('mr-0 ml-0'),
})
const Title = styled.h2(apply('my-6 text-xl font-light'))

function KeyContactsSection({ isEditing }) {
  return (
    <>
      <Title>Key Contacts</Title>
      <Details>
        <Column style={apply('flex-1')}>
          <Row>
            <FormInput
              name="companyDetails.keyContact.firstName"
              type="text"
              disabled={!isEditing}
              label="First Name"
            />
            <FormInput
              name="companyDetails.keyContact.lastName"
              type="text"
              disabled={!isEditing}
              label="Last Name"
            />
          </Row>
          <Row>
            <FormInput
              name="companyDetails.keyContact.phoneNumber"
              type="text"
              disabled={!isEditing}
              label="Mobile"
              placeholder="+61412341234"
            />
            <FormInput
              name="companyDetails.keyContact.email"
              type="text"
              disabled={!isEditing}
              label="Email"
            />
          </Row>
          <Row>
            <FormInput
              name="companyDetails.keyContact.defaultPassword"
              type="text"
              label="Default Password"
              value="Customerpass1!"
              isDefaultPass={true}
            />
          </Row>
        </Column>
      </Details>
    </>
  )
}

export { KeyContactsSection }
