import { isNil, prop, insert } from '@seedcloud/ramda-extra'
import { useState, useEffect, useCallback } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Link as LinkBase, useLocation } from 'react-router-dom'

import { ErrorPage } from '../ErrorPage'

import { NavigationBar } from './NavigationBar'

import { ReactComponent as JobsNormalIcon } from 'assets/briefcase.svg'
import { ReactComponent as JobsFilledIcon } from 'assets/briefcase_filled.svg'
import { ReactComponent as BuildingNormalIcon } from 'assets/building_office.svg'
import { ReactComponent as BuildingFilledIcon } from 'assets/building_office_filled.svg'
import { ReactComponent as CustomerNormalIcon } from 'assets/building_storefront.svg'
import { ReactComponent as CustomerFilledIcon } from 'assets/building_storefront_filled.svg'
import { ReactComponent as DocNormalIcon } from 'assets/document_plus.svg'
import { ReactComponent as DocFilledIcon } from 'assets/document_plus_filled.svg'
import { ReactComponent as PilotsNormalIcon } from 'assets/drone.svg'
import { ReactComponent as PilotsFilledIcon } from 'assets/drone_filled.svg'
import { ReactComponent as MyCompanyNormalIcon } from 'assets/my_company.svg'
import { ReactComponent as MyCompanyFilledIcon } from 'assets/my_company_filled.svg'
import { ReactComponent as MyPilotNormalIcon } from 'assets/remote.svg'
import { ReactComponent as MyPilotFilledIcon } from 'assets/remote_filled.svg'
import { ReactComponent as ProductNormalIcon } from 'assets/squares_2x2.svg'
import { ReactComponent as ProductFilledIcon } from 'assets/squares_2x2_filled.svg'
import { ReactComponent as StaffNormalIcon } from 'assets/user_group.svg'
import { ReactComponent as StaffFilledIcon } from 'assets/user_group_filled.svg'
import { getDefaultResource } from 'components/Dashboards' // eslint-disable-line import/no-cycle
import { RESOURCES } from 'constants/resources'
import { usePermissions } from 'lib/permissions'
import { useIdentity } from 'lib/solta-id-react'
import { styled, apply } from 'lib/styled'

const Container = styled.div(
  apply('flex-1 flex flex-row h-full', { overflow: 'hidden' })
)
const SideBar = styled.div(
  apply(
    'relative z-1 bg-white flex flex-column border-0 border-r-1 border-solid border-grey-light',
    {
      overflow: 'hidden',
      transition: '0.25s all ease, 0.25s box-shadow ease',
    }
  ),
  ({ isOpen }) => ({
    '--sidebar-width': '270px',
    width: isOpen ? 'var(--sidebar-width)' : 0,
    boxShadow: isOpen ? apply('shadow').boxShadow : 'none',
  })
)
const Branding = styled.div(
  apply(
    'flex items-center justify-center p-6 border-0 border-b-1 border-solid border-grey-light'
  )
)
const Logo = styled.img(apply('w-12 h-4', { objectFit: 'contain' }))
const linkStyles = {
  base: apply(
    'tracking-wide flex flex-row items-center text-sm font-normal p-4 text-black border-0 border-b-1 border-solid border-grey-lighter',
    {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      '&:hover, &:focus': apply('no-decoration'),
    }
  ),
  active: {
    fontWeight: 'bold',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '-8px',
      width: '16px',
      backgroundColor: '#009DE4',
      borderRadius: '16px',
    },
  },
  inactive: {
    backgroundColor: '#fff',
    fontWeight: 'normal',
    borderLeft: 'none',
    borderRadius: '0',
  },
}

const Link = styled(LinkBase, { shouldForwardProp: (p) => p !== 'active' })(
  linkStyles.base,
  ({ active }) => (active ? linkStyles.active : linkStyles.inactive)
)

const Icon = styled.i(
  apply('flex flex-row justify-center w-2 mr-2 text-blue-400 text-base')
)

const Content = styled.div(
  apply('flex-1 flex flex-column bg-blue-50', {
    '& > *': {
      overflow: 'hidden',
    },
  }),
  ({ isOpen }) => ({
    '--sidebar-width': '270px',
    maxWidth: isOpen ? 'calc(100% - var(--sidebar-width))' : '100%',
  })
)

// ATTENTION: paths contain [plural, singular] form and the order is convention only.
const routes = [
  {
    resource: RESOURCES.PILOT_APPLICATION,
    title: 'Applications',
    paths: ['/applications', '/application'],
    icon: 'applications',
  },
  {
    resource: RESOURCES.PILOT_DETAILS,
    title: 'Pilots',
    paths: ['/pilots', '/pilot'],
    icon: 'pilots',
  },
  {
    resource: RESOURCES.MY_COMPANY,
    title: 'My Company',
    paths: ['/company'],
    icon: 'mycompany',
  },
  {
    resource: RESOURCES.JOB,
    title: 'Jobs',
    paths: ['/jobs', '/job'],
    icon: 'jobs',
  },
  {
    resource: RESOURCES.MY_PILOT_INFO,
    title: 'My Pilot Info',
    paths: ['/my-pilot-info'],
    icon: 'mypilot',
  },
  {
    resource: RESOURCES.PRODUCT,
    title: 'Products',
    paths: ['/products', '/product'],
    icon: 'product',
  },
  {
    resource: RESOURCES.STAFF,
    title: 'Staff',
    paths: ['/staff'],
    icon: 'staff',
  },
  {
    resource: RESOURCES.SUPPLIER,
    title: 'Supplier',
    paths: ['/suppliers', '/supplier'],
    icon: 'building',
  },
  {
    resource: RESOURCES.CUSTOMER,
    title: 'Customer',
    paths: ['/customer'],
    icon: 'building',
  },
]

const iconComponents = {
  applications: {
    normal: DocNormalIcon,
    filled: DocFilledIcon,
  },
  pilots: {
    normal: PilotsNormalIcon,
    filled: PilotsFilledIcon,
  },
  mycompany: {
    normal: MyCompanyNormalIcon,
    filled: MyCompanyFilledIcon,
  },
  mypilot: {
    normal: MyPilotNormalIcon,
    filled: MyPilotFilledIcon,
  },
  jobs: {
    normal: JobsNormalIcon,
    filled: JobsFilledIcon,
  },
  product: {
    normal: ProductNormalIcon,
    filled: ProductFilledIcon,
  },
  staff: {
    normal: StaffNormalIcon,
    filled: StaffFilledIcon,
  },
  building: {
    normal: BuildingNormalIcon,
    filled: BuildingFilledIcon,
  },
  customer: {
    normal: CustomerNormalIcon,
    filled: CustomerFilledIcon,
  },
  // add more icon components here
}

const iconStyles = {
  shapeRendering: 'auto',
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
  vectorEffect: 'non-scaling-stroke',
}

// this ensures that the rendered routes are ordered by the order specified in constants/resources
const reorderRoutes = (prev, current) => {
  const order = Object.values(RESOURCES)
  const index = order.findIndex((r) => r === current.resource)

  return insert(index, current, prev)
}

function withNavigation(WrappedComponent) {
  return function NavigationComponent(ownProps) {
    const [showSidebar, setShowSidebar] = useState(true)
    const location = useLocation()
    const permissions = usePermissions()
    const { organization, role } = useIdentity()
    const orgLogoSrc = prop('logo', organization) ?? '/nearbysky-logo.png'
    const visibleRoutes = routes
      .filter((r) => permissions.can('read', r.resource))
      .reduce(reorderRoutes, [])

    const defaultRoute = visibleRoutes.find(
      (route) => route.resource === getDefaultResource(role)
    )

    const [currentRoute, setCurrentRoute] = useState(defaultRoute)

    useEffect(() => {
      const newCurrentRoute = visibleRoutes.find((route) =>
        route.paths.some((path) => location.pathname.includes(path))
      )

      if (isNil(newCurrentRoute)) {
        return
      }

      setCurrentRoute(newCurrentRoute)
    }, [location])

    const onSidebarToggle = useCallback(() => {
      setShowSidebar(!showSidebar)
    })

    return (
      <Container>
        <SideBar isOpen={showSidebar}>
          <Branding>
            <Logo src={orgLogoSrc} data-test-id="logo" />
          </Branding>

          {visibleRoutes.map(({ resource, paths, title, icon }) => {
            const IconNormal = iconComponents[icon]?.normal
            const IconFilled = iconComponents[icon]?.filled

            return (
              <Link
                key={resource}
                to={paths[0]}
                active={resource === currentRoute?.resource}
              >
                <Icon>
                  {IconNormal && IconFilled && (
                    <>
                      {resource === currentRoute?.resource ? (
                        <IconFilled style={iconStyles} />
                      ) : (
                        <IconNormal style={iconStyles} />
                      )}
                    </>
                  )}
                </Icon>
                {title}
              </Link>
            )
          })}
        </SideBar>

        <Content isOpen={showSidebar}>
          <NavigationBar
            title={currentRoute.title}
            showSidebar={showSidebar}
            onSidebarToggle={onSidebarToggle}
          />
          <ErrorBoundary FallbackComponent={ErrorPage} resetKeys={[currentRoute]}>
            <WrappedComponent {...ownProps} />
          </ErrorBoundary>
        </Content>
      </Container>
    )
  }
}

export { withNavigation }
