import { connect } from '@seedcloud/stateless'
import { useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { CompanyListItem } from './CompanyListItem'
import { FilterInput } from './FilterInput'

import { ReactComponent as PlusIcon } from 'assets/feathers/plus.svg'
import { Button as ButtonBase } from 'components/common/Button'
import { Loader } from 'components/common/Loader'
import { Spinner } from 'components/common/Spinner'
import { CanCreateCompany } from 'lib/permissions'
import { styled, apply } from 'lib/styled'
import { createCompanyModule } from 'modules/legacy-company'

const {
  module: companyModule,
  selectors: { companyList$, inspectedEntity$, isLoadingCompany$ },
} = createCompanyModule()

const Container = styled.div(
  apply('flex flex-column w-1/4 min-w-10 max-w-24 mr-6 bg-white rounded-lg shadow-sm', {
    overflow: 'hidden',
  })
)

const FilterContainer = styled.div(
  apply('p-4 border-0 border-b-1 border-solid border-grey-lighter')
)
const InputContainer = styled.div(apply('flex mb-4'))

const CreateCompanyButton = styled(ButtonBase)(
  apply('flex justify-center p-2 mx-1 items-center', {
    width: '30px',
    boxSizing: 'content-box',
  })
)

const List = styled.ol(
  apply('p-0 m-0', {
    overflow: 'auto',
    listStyleType: 'none',
  })
)

const ConnectedCompanyList = connect(() => ({
  companyList: companyList$,
  isLoadingCompany: isLoadingCompany$,
  inspectedEntity: inspectedEntity$,
}))(CompanyList)

function CompanyList({ companyList, isLoadingCompany, inspectedEntity, ...props }) {
  const history = useHistory()

  useLayoutEffect(() => {
    companyModule.filterCompanies(null, { filterType: 'customer' })
  }, [])

  return (
    <Container {...props}>
      <FilterContainer>
        <InputContainer>
          <FilterInput containerProps={{ style: apply('flex-1') }} />
          <CanCreateCompany>
            <CreateCompanyButton
              onClick={() => {
                history.push('/customer/create')
              }}
              style={apply('text-blue-700')}
            >
              <PlusIcon width={17} height={17} />
            </CreateCompanyButton>
          </CanCreateCompany>
        </InputContainer>
      </FilterContainer>

      <Loader
        loading={isLoadingCompany}
        renderPlaceholder={<Spinner size={32} thickness={6} style={apply('mt-4')} />}
      >
        <List>
          {companyList.map((company) => {
            const isInspected = company.id === inspectedEntity

            const onInspectCompany = () => {
              history.push(`/customer/${company.id}`)
            }

            return (
              <CompanyListItem
                key={company.id}
                isInspected={isInspected}
                company={company}
                onClick={onInspectCompany}
              />
            )
          })}
        </List>
      </Loader>
    </Container>
  )
}

export { ConnectedCompanyList as CompanyList }
